<!-- eslint-disable no-console -->
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../common/axios";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "profile",
      },
      title: "Profile",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Edit Profile Detail",
          href: "/profile",
          active: true,
        },
      ],
      model: {
        email: null,
        full_name: null,
        profile_picture: null,
      },
    };
  },
  methods: {
    clickref() {
      console.log(this.$refs.file.$el.files[0]);
      this.model.profile_picture = this.$refs.file.$el.files[0];
      let reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      reader.onload = (e) => {
        this.$refs.image_tmb.src = reader.result;
      };
      reader.readAsDataURL(this.model.profile_picture);
      this.$forceUpdate();
    },
    updtaeProfile() {
      let _ = this;
      let formData = new FormData();
      if (typeof this.profile_picture !== "string") {
        formData.append("full_name", _.model.full_name);
        formData.append("email", _.model.email);
        formData.append("profile_picture", this.$refs.file.$el.files[0]);
      } else {
        formData.append("full_name", _.model.full_name);
        formData.append("email", _.model.email);
      }
      api
        .put(_.config.api, formData)
        .then(() => {
          _.$toast("Edit Profile Success", {
            icon: "fas fa-check-circle",
            type: 'success',
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getData() {
      let _ = this;
      api.get(_.config.api).then((response) => {
        let _ = this;
        _.model = response.data.data;
        console.log(_.model);
      });
    },
  },
  mounted() {
    let _ = this;
    _.getData();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div class="p-4">
                  <b-img
                    ref="image_tmb"
                    :src="
                      model.profile_picture
                        ? model.profile_picture
                        : 'https://via.placeholder.com/200.png?text=No+Image'
                    "
                    rounded="circle"
                    alt="100x100"
                    width="100"
                    height="100"
                  ></b-img>
                </div>

                <b-button
                  variant="primary"
                  class="w-70 text-center mt-1 ml-3"
                  @click="$refs.file.$el.click()"
                  >Upload Photo</b-button
                >
                <b-form-file
                  ref="file"
                  @input="clickref"
                  accept=".jpg, .png, .gif"
                  :hidden="true"
                  plain
                />
              </div>

              <div class="col-md-6 mt-4">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(updtaeProfile)"
                    class="form-horizontal "
                  >
                    <ValidationProvider
                      name="Name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Nama"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.full_name"
                          type="text"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-1"
                        label="Email"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="model.email"
                          readonly
                          type="email"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="form-group row">
                      <div class="col-sm-4 ">
                        <b-button
                          type="button"
                          to="/change-password"
                          variant="light"
                          class="w-100"
                          >Ubah Password</b-button
                        >
                      </div>

                      <div class="col-sm-3 ">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Simpan</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
